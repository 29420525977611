import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Facebook, Instagram, YouTube } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#333', // Dark gray background
        color: 'white',
        textAlign: 'center',
        py: 2, // Padding on the y-axis
      }}
    >
      {/* Social Media Icons */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 1, // Margin below icons
        }}
      >
        <IconButton
          sx={{ color: 'white', mx: 1 }} // White icons with spacing
          href="https://www.facebook.com/profile.php?id=61554094175322&mibextid=ZbWKwL"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Facebook />
        </IconButton>
        <IconButton
          sx={{ color: 'white', mx: 1 }}
          href="https://instagram.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Instagram />
        </IconButton>
        <IconButton
          sx={{ color: 'white', mx: 1 }}
          href="https://www.youtube.com/@petracooling"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YouTube />
        </IconButton>
      </Box>

      {/* Copyright Text */}
      <Typography variant="body2" sx={{ fontSize: '14px', mt: 1 }}>
        © {new Date().getFullYear()} Petra Cooling. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
