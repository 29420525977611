import React, { useEffect } from "react";
import Slider from "react-slick";
import { FaQuoteLeft } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Carousal.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography } from "@mui/material";

// Updated testimonials with new names and no image or subname
const testimonials = [
    {
        heading: "Exceptional Service",
        description:
            "Petra Cooling's team installed our bus AC systems flawlessly. Their expertise and attention to detail ensured the entire process was smooth. Highly recommended!",
        name: "Princy Travels , Ernakulam",
    },
    {
        heading: "Reliable and Efficient",
        description:
            "Our Traveller's AC system was serviced on-site by Petra Cooling, and they did an outstanding job. The technicians were professional and quick. Truly dependable service!",
        name: "Pepper Kerala , Ernakulam",
    },
    {
        heading: "Quality You Can Trust",
        description:
            "As an authorized dealer for PKN AC systems, Petra Cooling has always delivered exceptional quality. Their products and services have exceeded our expectations time and again.",
        name: "Joy Attighal",
    },
    {
        heading: "Outstanding Performance",
        description:
            "The team at Petra Cooling was prompt and efficient. The AC system installed in our vehicle is working perfectly, and we're very satisfied with the results.",
        name: "Libin Varghese, Mavelikara",
    },
    {
        heading: "Excellent Service",
        description:
            "We entrusted Petra Cooling with the installation of an AC system for our fleet, and the results have been outstanding. Their team was professional, efficient, and highly skilled.",
        name: "Advik Holidays, Kannur",
    },
    {
        heading: "Highly Recommended",
        description:
            "Petra Cooling delivered excellent service for our vehicle's AC system. The quality of their work is unmatched, and we are extremely satisfied with the end result.",
        name: "Bibin Nilaboor",
    },
    {
        heading: "Superb Quality and Service",
        description:
            "The team at Petra Cooling exceeded our expectations. Their installation of our AC system was flawless, and the performance has been top-notch. Highly recommended!",
        name: "Nadhu TVM",
    },
    {
        heading: "Reliable Partner",
        description:
            "The AC systems provided by Petra Cooling have been a game-changer for our fleet. Their after-sales service is also commendable.",
        name: "Deminos Trous Travales, Kaladi",
    },
    {
        heading: "Exceptional Experience",
        description:
            "Petra Cooling has consistently delivered top-tier service for our vehicles' AC systems. Their expertise and quality are unparalleled.",
        name: "Mazhavil Travels, Perumbavoor",
    },
    {
        heading: "Professional and Efficient",
        description:
            "Our AC system installation was handled expertly by Petra Cooling. Their team was highly professional, ensuring excellent results.",
        name: "Nila Travel, Thodupuzha",
    },
    {
        heading: "Top-Notch Service",
        description:
            "We are delighted with Petra Cooling's service. The AC system for our bus was installed with precision and has been working flawlessly.",
        name: "Falcon Travels, Kannur",
    },
    {
        heading: "Skilled Team",
        description:
            "Petra Cooling's team showcased unmatched professionalism while installing our AC system. Their service quality is exceptional.",
        name: "Ayilam Travels, Kozhikode",
    },
    {
        heading: "Highly Satisfied",
        description:
            "From installation to after-sales service, Petra Cooling has been excellent. We trust them for all our AC system needs.",
        name: "Dass Travels, Thrissur",
    },
    {
        heading: "Trusted Partner",
        description:
            "Petra Cooling installed our fleet's AC systems with precision and professionalism. Their service has been consistently outstanding.",
        name: "Chithra Travels, Ottapalam",
    },
];


const TestimonialsPage = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration in milliseconds
            once: true, // Ensure animation occurs only once while scrolling
        });
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="testimonials-page">
            {/* Heading with AOS animation */}
            <Box sx={{ textAlign: "center", mb: 2 }} data-aos="fade-up">
                <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1, fontFamily: "'Montserrat', sans-serif", }}>
                    TESTIMONIALS
                </Typography>
                <Box
                    sx={{
                        width: "60px",
                        height: "3px",
                        backgroundColor: "blue",
                        mx: "auto",
                    }}
                />
            </Box>

            {/* Carousel with AOS animation */}
            <Slider {...settings}>
                {testimonials.map((testimonial, index) => (
                    <div
                        key={index}
                        className="testimonial-item mx-3"
                        data-aos="fade-up" // Animation for each slide
                        data-aos-delay={index * 100} // Staggered delay effect
                    >
                        <div className="speech-bubble">
                            <FaQuoteLeft className="quote-icon" />
                            <h5>{testimonial.heading}</h5>
                            <p>{testimonial.description}</p>
                        </div>
                        {/* Removed the image and subname */}
                        <div
                            className="testimonial-footer mt-3"
                            data-aos="fade-up" // Animation for footer
                            data-aos-delay={index * 150} // Slightly different delay for footer
                        >
                            <strong>{testimonial.name}</strong>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default TestimonialsPage;
