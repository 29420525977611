import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Divider, Modal, IconButton, Button } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import CloseIcon from "@mui/icons-material/Close";
import image1 from '../../assets/img3.JPG';
import image2 from '../../assets/img4.JPG';
import image3 from '../../assets/img5.JPG';
import image4 from '../../assets/img6.JPG';
import image5 from '../../assets/img7.JPG';
import image6 from '../../assets/img8.JPG';
import image7 from '../../assets/img9.JPG';
import image8 from '../../assets/img1.JPG';
import image9 from '../../assets/img2.JPG';

const Gallery = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [showAllImages, setShowAllImages] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Ensures animation occurs only once while scrolling
    });
  }, []);

  const images = [
    image8,
    image4,
    image2,
    image3,
    image9,
    image1,
    image5,
    image6,
    image7,
  ];

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage("");
  };

  const toggleImages = () => {
    setShowAllImages((prev) => !prev);
  };

  return (
    <Box
      sx={{
        padding: "40px 60px",
        textAlign: "center",
        backgroundColor: "#fff",
      }}
    >
      {/* Title with AOS Animation */}
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", marginBottom: "10px", fontFamily: "'Montserrat', sans-serif" }}
        data-aos="fade-up"
      >
        OUR GALLERY
      </Typography>
      <Divider
        sx={{
          width: "60px",
          height: "2px",
          backgroundColor: "#003366",
          margin: "10px auto 20px",
        }}
        data-aos="fade-up"
        data-aos-delay="200" // Delay for animation
      />

      {/* Gallery Grid with Images */}
      <Grid container spacing={2} justifyContent="center">
        {(showAllImages ? images : images.slice(0, 6)).map((image, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            data-aos="zoom-in" // Animation type for grid items
            data-aos-delay={index * 100} // Staggered animation delay
          >
            <Box
              component="img"
              src={image}
              alt={`Gallery Image ${index + 1}`}
              onClick={() => handleOpen(image)}
              sx={{
                width: "100%",
                height: "200px",
                borderRadius: "8px",
                objectFit: "cover", // Ensures the image fills the box proportionally
                cursor: "pointer", // Changes the cursor to pointer on hover
                transition: "transform 0.3s ease", // Smooth zoom-in transition
                "&:hover": {
                  transform: "scale(1.05)", // Zoom in effect
                },
              }}
            />
          </Grid>
        ))}
      </Grid>

      {/* Toggle Button for View More / View Less */}
      <Button
        onClick={toggleImages}
        sx={{
          marginTop: "20px",
          padding: "10px 20px",
          backgroundColor: "#003366",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#002244",
          },
        }}
      >
        {showAllImages ? "View Less" : "View More"}
      </Button>

      {/* Modal for Image Preview */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            boxShadow: 24,
            padding: 2,
            outline: "none",
            borderRadius: "8px",
            maxWidth: "90%",
            maxHeight: "90%",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "8px",
              right: "8px",
              zIndex: 10,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            component="img"
            src={selectedImage}
            alt="Preview"
            sx={{
              width: "100%",
              height: "auto",
              maxHeight: "90vh",
              borderRadius: "8px",
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default Gallery;
