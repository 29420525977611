import React, { useEffect, useState } from "react";
import {
    Container,
    Box,
    Typography,
    Grid,
    TextField,
    Button,
    IconButton,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AOS from "aos";
import "aos/dist/aos.css";

const ContactUs = () => {
    // Initialize AOS on page load
    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Ensure animation happens only once
        });
    }, []);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        comments: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () => {
        const { name, email, phone, comments } = formData;

        // Construct WhatsApp message
        const message = `Hello, I would like to get in touch!%0A%0A
        Name: ${encodeURIComponent(name)}%0A
        Email: ${encodeURIComponent(email)}%0A
        Phone: ${encodeURIComponent(phone)}%0A
        Message: ${encodeURIComponent(comments)}`;

        // WhatsApp API URL
        const whatsappURL = `https://wa.me/+919746871022?text=${message}`; // Replace with your WhatsApp number

        // Redirect to WhatsApp URL
        window.open(whatsappURL, "_blank");
    };

    return (
        <Container maxWidth="lg">
            {/* Map Section */}
            <Box
                sx={{
                    height: 300,
                    backgroundColor: "#e0e0e0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 4,
                }}
                data-aos="fade-up" // Animation for the map section
            >
                {/* Embedded Google Map */}
                <iframe
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    style={{ border: "0" }}
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3927.425795856761!2d76.35047527503313!3d10.146001789966999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDA4JzQ1LjYiTiA3NsKwMjEnMTEuMCJF!5e0!3m2!1sen!2sin!4v1732612453773!5m2!1sen!2sin"
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                ></iframe>
            </Box>


            {/* Contact Information */}
            <Grid container spacing={3} sx={{ textAlign: "center", mb: 4 }}>
                <Grid item xs={12} sm={4} data-aos="fade-up">
                    <IconButton>
                        <LocationOnIcon sx={{ fontSize: 40, color: "blue" }} />
                    </IconButton>
                    <Typography sx={{ fontFamily: "'Montserrat', sans-serif",}}>
                        Building No. 17/678, Post Office Junction, Nedumbassery P.O., <br />
                        Kerala - 683585
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} data-aos="fade-up" data-aos-delay="100">
                    <IconButton>
                        <PhoneIcon sx={{ fontSize: 40, color: "blue" }} />
                    </IconButton>
                    <Typography sx={{ fontFamily: "'Montserrat', sans-serif",}}>
                        +91 8281640651 <br />
                        petracoolingsystems@gmail.com
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} data-aos="fade-up" data-aos-delay="200">
                    <IconButton>
                        <AccessTimeIcon sx={{ fontSize: 40, color: "blue" }} />
                    </IconButton>
                    <Typography sx={{ fontFamily: "'Montserrat', sans-serif",}}>
                        Mon - Sat <br />
                        9:00am - 7:00pm
                    </Typography>
                </Grid>
            </Grid>

            {/* Contact Form */}
            <Box sx={{ textAlign: "center", mb: 2 }}>
                <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1, fontFamily: "'Montserrat', sans-serif", }}>
                    SEND US A MESSAGE
                </Typography>
                <Box
                    sx={{
                        width: "60px",
                        height: "3px",
                        backgroundColor: "blue",
                        mx: "auto",
                    }}
                />
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="Your Name"
                        variant="outlined"
                        size="small"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="Your Email"
                        variant="outlined"
                        size="small"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="Phone Number"
                        variant="outlined"
                        size="small"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Message"
                        variant="outlined"
                        multiline
                        rows={4}
                        name="comments"
                        value={formData.comments}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ display: "block", mx: "auto", marginBottom: "20px" , fontFamily: "'Montserrat', sans-serif",}}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ContactUs;
