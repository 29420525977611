import React, { useEffect } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download"; // Import the download icon
import AOS from "aos";
import "aos/dist/aos.css";

const WelcomeSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Ensures animation occurs only once while scrolling
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#F5F5F5",
        padding: 4,
      }}
    >
      <Grid container spacing={4} alignItems="center">
        {/* Text Content */}
        <Grid item xs={12} md={6} data-aos="fade-up">
          <Typography
            variant="h6"
            sx={{
              color: "#0056A3",
              fontWeight: "bold",
              textTransform: "uppercase",
              mb: 2,
              fontFamily: "'Montserrat', sans-serif",
            }}
          >
            Welcome to Petra Cooling
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              mb: 2,
              lineHeight: 1.4,
              fontFamily: "'Montserrat', sans-serif",
            }}
          >
            Keeping Journeys <br />
            Cool with Precision
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#666",
              mb: 3,
              fontFamily: "'Montserrat', sans-serif",
            }}
          >
            Petra Cooling is an authorized dealer and service expert for PKN
            Bus & Traveller Air Conditioning Systems. Based in Nedumbassery,
            Cochin, we specialize in professional AC installations carried out
            by our skilled technicians. With a commitment to quality, we
            provide reliable on-site service across South India.
          </Typography>
          <a
            href="Brochure.pdf" // Correct relative path
            download="PetraCooling_Brochure.pdf"
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#0056A3",
                textTransform: "none",
                fontFamily: "'Montserrat', sans-serif",
              }}
              startIcon={<DownloadIcon />}
            >
              Download Brochure
            </Button>
          </a>

        </Grid>

        {/* Image Section */}
        <Grid item xs={12} md={6} data-aos="fade-up">
          <Box
            component="img"
            src="https://i.postimg.cc/vTTn21zs/Screenshot-2024-11-26-105857.png" // Replace with your image URL
            alt="Bus"
            sx={{
              width: "100%",
              borderRadius: 1,
              boxShadow: 3,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WelcomeSection;
