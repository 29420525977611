import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-scroll";

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const navItems = ["HOME", "ABOUT US", "SERVICES", "GALLERY", "CONTACTUS"];

  // State for form data
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    message: "",
  });

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(!openMenu);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = () => {
    const { name, number, email, message } = formData;

    // Validate input (optional, can be enhanced)
    if (!name || !number || !email || !message) {
      alert("Please fill all fields!");
      return;
    }

    // WhatsApp redirect URL
    const whatsappMessage = `Name: ${name}%0aPhone: ${number}%0aEmail: ${email}%0aMessage: ${message}`;
    const whatsappURL = `https://wa.me/+919746871022?text=${whatsappMessage}`;

    // Redirect to WhatsApp
    window.open(whatsappURL, "_blank");

    // Close dialog
    handleDialogClose();
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* Logo */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src="https://i.postimg.cc/SRRZLxYX/Screenshot-2024-11-26-110029-removebg-preview.png"
              alt="Logo"
              style={{ height: 50, marginRight: 10 }}
            />
          </Box>

          {/* Desktop Navigation Menu */}
          {!isMobile ? (
            <Box sx={{ display: "flex", gap: 2 }}>
              {navItems.map((item) =>
                item === "CONTACTUS" ? (
                  <Button
                    key={item}
                    onClick={handleDialogOpen} // Open modal for ContactUs
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      "&:hover": { color: theme.palette.primary.main },
                      fontFamily: "'Montserrat', sans-serif",
                    }}
                  >
                    {item}
                  </Button>
                ) : (
                  <Link
                    key={item}
                    to={item.toLowerCase().replace(" ", "")}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <Button
                      sx={{
                        color: "black",
                        fontWeight: "bold",
                        "&:hover": { color: theme.palette.primary.main },
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      {item}
                    </Button>
                  </Link>
                )
              )}
            </Box>
          ) : (
            // Mobile Menu Button
            <IconButton onClick={handleMenuClick}>
              <MenuIcon sx={{ color: "black" }} />
            </IconButton>
          )}

          {/* Mobile Menu */}
          {isMobile && openMenu && (
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{ mt: 2 }}
            >
              {navItems.map((item) =>
                item === "CONTACTUS" ? (
                  <MenuItem key={item} onClick={handleDialogOpen}>
                    {item}
                  </MenuItem>
                ) : (
                  <Link
                    key={item}
                    to={item.toLowerCase().replace(" ", "")}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <MenuItem onClick={handleCloseMenu}>{item}</MenuItem>
                  </Link>
                )
              )}
            </Menu>
          )}
        </Toolbar>
      </AppBar>

      {/* Contact Us Dialog */}
      {/* Contact Us Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: "primary.main",
            fontFamily: "'Montserrat', sans-serif",
          }}
        >
          Contact Us
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ mt: 2 }}
          >
            <TextField
              label="Name"
              name="name"
              fullWidth
              margin="dense"
              value={formData.name}
              onChange={handleInputChange}
              variant="outlined"
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                "& fieldset": { border: "none" },
                mb: 2,
              }}
            />
            <TextField
              label="Phone Number"
              name="number"
              fullWidth
              margin="dense"
              value={formData.number}
              onChange={handleInputChange}
              variant="outlined"
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                "& fieldset": { border: "none" },
                mb: 2,
              }}
            />
            <TextField
              label="Email"
              name="email"
              fullWidth
              margin="dense"
              value={formData.email}
              onChange={handleInputChange}
              variant="outlined"
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                "& fieldset": { border: "none" },
                mb: 2,
              }}
            />
            <TextField
              label="Message"
              name="message"
              fullWidth
              multiline
              rows={4}
              margin="dense"
              value={formData.message}
              onChange={handleInputChange}
              variant="outlined"
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                "& fieldset": { border: "none" },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            px: 3,
            pb: 2
          }}
        >
          <Button
            onClick={handleDialogClose}
            variant="outlined"
            color="error"
            sx={{
              textTransform: "capitalize",
              px: 3,
              borderRadius: "10px"
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleFormSubmit}
            variant="contained"
            color="primary"
            sx={{
              textTransform: "capitalize",
              px: 3,
              borderRadius: "10px"
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>


    </>
  );
};

export default Header;
