import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import gif from '../../assets/background.gif'; // Replace with your GIF path

const DealerSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether the animation should happen only once
    });
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: "white",
        height: "50vh",
        padding: 2,
        overflow: "hidden",
        zIndex: 1,
      }}
    >
      {/* GIF Background */}
      <Box
        component="img"
        src={gif} // Your GIF file path
        alt="Background GIF"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1, // Places the GIF behind the content
        }}
      />

      {/* Black Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Black shadow with 50% opacity
          zIndex: 0, // Places the overlay above the video but behind the content
        }}
      />

      {/* Main Heading */}
      <Typography
        variant="h5"
        sx={{
          fontWeight: 400,
          mb: 1,
          fontFamily: "'Montserrat', sans-serif",
        }}
        data-aos="fade-down"
      >
        Authorized Dealer in South India for
      </Typography>

      {/* Brand Logo or Name */}
      <Box
        component="img"
        src="https://i.postimg.cc/PJT3CMTM/Screenshot-2024-11-26-105731-removebg-preview.png" // Replace with the path to your logo file
        alt="PKN Logo"
        sx={{ height: 60, mb: 1 }} // Adjust height and margin as needed
        data-aos="zoom-in"
      />

      {/* Subheading */}
      <Typography
        variant="h5"
        sx={{
          fontWeight: 400,
          mb: 1,
          fontFamily: "'Montserrat', sans-serif",
        }}
        data-aos="fade-up"
      >
        Bus & Traveller Air Conditioning Systems
      </Typography>
    </Box>
  );
};

export default DealerSection;
