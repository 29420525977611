import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";

const SupportedVehicles = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Ensures animation occurs only once while scrolling
    });
  }, []);

  const vehicles = [
    "FORCE TRAX CRUISER",
    "FORCE TRAX AMBULANCE",
    "FORCE WHEEL BASE 3050 - 12 SEAT",
    "FORCE WHEEL BASE 4020 T1 - 20 SEAT",
    "FORCE WHEEL BASE 4020 T2 - 25 SEAT",
    "FORCE MONOBUS 4020 - 33 SEAT",
    "FORCE WHEEL BASE 3700 - 17 SEAT",
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#bfbebb",
        padding: "20px",
        textAlign: "center",
      }}
    >
      {/* Heading with fade-up animation */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          color: "#003366",
          marginBottom: "20px",
          fontFamily: "'Montserrat', sans-serif",
        }}
        data-aos="fade-up"
      >
        THE VEHICLES WE SUPPORT
      </Typography>

      {/* List of vehicles with staggered animations */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        {vehicles.map((vehicle, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
            data-aos="fade-up"
            data-aos-delay={index * 100} // Adds a staggered delay effect
          >
            <Box
              sx={{
                width: "8px",
                height: "8px",
                backgroundColor: "#FFCC00",
                borderRadius: "50%",
              }}
            />
            <Typography
              variant="body2"
              sx={{
                color: "#333",
                fontFamily: "'Montserrat', sans-serif",
              }}
            >
              {vehicle}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SupportedVehicles;
