import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import DealerSection from "../components/Home/Section1";
import WelcomeSection from "../components/Home/Section2";
import SupportedVehicles from "../components/Home/Section3";
import WhyTrustPetraCooling from "../components/Home/Section4";
import Gallery from "../components/Home/Section5";
import Testimonials from "../components/Home/Section6";
import ContactUs from "../components/Home/Section7";
import Footer from "../components/Footer";
import "./Home.css";
import { FaWhatsapp } from "react-icons/fa"; // Import WhatsApp icon

function Home() {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    // Hide splash screen after 2 seconds
    const splashTimeout = setTimeout(() => {
      setShowSplash(false);
    }, 2000);

    return () => {
      clearTimeout(splashTimeout);
    };
  }, []);

  return (
    <div>
      {/* Splash Screen */}
      <div className={`splash-screen ${!showSplash ? "hidden" : ""}`}>
        <img
          src="https://i.postimg.cc/SRRZLxYX/Screenshot-2024-11-26-110029-removebg-preview.png"
          alt="Resourzone Logo"
          className="splash-logo"
        />
        <div className="splash-text" style={{ fontFamily: "'Montserrat', sans-serif",}}>Welcome to Petra Cooling</div>
      </div>

      {/* Home Content */}
      <div className={`home-content ${showSplash ? "hidden" : ""}`}>
        <Header />
        <div id="home" style={{ marginTop: "20px" }}>
          <DealerSection />
        </div>
        <div id="aboutus">
          <WelcomeSection />
        </div>
        <div id="services">
          <SupportedVehicles />
        </div>
        <div>
          <WhyTrustPetraCooling />
        </div>
        <div id="gallery">
          <Gallery />
        </div>
        <div>
          <Testimonials />
        </div>
        <div id="contactus">
          <ContactUs />
        </div>
        <Footer />

        {/* WhatsApp Floating Button */}
        <a
          href="https://wa.me/+919746871022?text=Hello!"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-button"
        >
          <FaWhatsapp size={24} />
        </a>
      </div>
    </div>
  );
}

export default Home;
