import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Divider } from "@mui/material";
import CountUp from "react-countup"; // Import the CountUp library
import AOS from "aos";
import "aos/dist/aos.css";

const WhyTrustPetraCooling = () => {
  const [startCount, setStartCount] = useState(false); // State to control when to start counting
  const sectionRef = useRef(null); // Ref for the section

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Ensures animation occurs only once while scrolling
    });

    // Intersection Observer to detect when the section is in the viewport
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCount(true); // Start counting when the section is visible
          observer.disconnect(); // Disconnect the observer after triggering
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the section is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (observer && sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <Box
      ref={sectionRef}
      sx={{
        backgroundColor: "#333",
        color: "#fff",
        padding: "40px 20px",
        textAlign: "center",
      }}
    >
      {/* Title with fade-up animation */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          marginBottom: "10px",
          fontFamily: "'Montserrat', sans-serif",
        }}
        data-aos="fade-up"
      >
        WHY TRUST PETRA COOLING?
      </Typography>
      <Divider
        sx={{
          width: "60px",
          height: "3px",
          backgroundColor: "#FFCC00",
          margin: "10px auto",
        }}
        data-aos="fade-up"
      />

      {/* Statistics Section with fade-up animation */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          margin: "20px 0",
        }}
        data-aos="fade-up"
      >
        {/* Years of Experience */}
        <Box>
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", color: "#F3B603" }}
          >
            {startCount ? <CountUp start={0} end={8} duration={2} /> : "0"}+
          </Typography>
          <Typography variant="body2" sx={{ fontFamily: "'Montserrat', sans-serif" }}>
            Years Of Experience
          </Typography>
        </Box>

        {/* Vehicles Completed */}
        <Box>
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", color: "#F3B603" }}
          >
            {startCount ? <CountUp start={0} end={400} duration={2.5} /> : "0"}+
          </Typography>
          <Typography variant="body2" sx={{ fontFamily: "'Montserrat', sans-serif" }}>
            Vehicles Completed
          </Typography>
        </Box>
      </Box>

      {/* Warranty and Servicing Information with fade-up animation */}
      <Box
        sx={{ textAlign: "center", maxWidth: "600px", margin: "0 auto" }}
        data-aos="fade-up"
      >
        <Typography
          variant="subtitle1"
          color="warning"
          sx={{
            fontWeight: "bold",
            marginTop: "20px",
            fontFamily: "'Montserrat', sans-serif",
          }}
        >
          Warranty:
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontFamily: "'Montserrat', sans-serif" }}
        >
          The product comes with a one-year replacement warranty.
        </Typography>
        <Typography
          variant="subtitle1"
          color="warning"
          sx={{
            fontWeight: "bold",
            marginTop: "20px",
            fontFamily: "'Montserrat', sans-serif",
          }}
        >
          Servicing:
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontFamily: "'Montserrat', sans-serif" }}
        >
          Spot servicing will be provided at any location within Kerala,
          Tamilnadu, Andhra & Telangana.
        </Typography>
      </Box>
    </Box>
  );
};

export default WhyTrustPetraCooling;
